import * as FileSaver from "file-saver";
import Echo from "laravel-echo";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { element } from "protractor";
import { forkJoin, fromEvent, Subscription } from "rxjs";
import { UserService } from "src/app/services/app/user/user.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { FormulaService } from "src/app/services/formulas-service.service";
import { FullScreenService } from "src/app/services/full-screen.service";
import { NotificationsService } from "src/app/services/notifications.service";
import { PortfolioService } from "src/app/services/portfolio.service";
import { RuntimeEnvLoaderService } from "src/app/services/runtime-env-loader.service";
import { StrategiesService } from "src/app/services/strategies.service";
import { UtilsService } from "src/app/services/utils.service";
import Swal from "sweetalert2";

import { HttpParams } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { SortService } from "../historical-orders/sort.service";
import {
  FilterResult,
  FilterService
} from "../table-components/filter.service";
import { UserNotificationTableComponent } from "../table-components/user-notification-table/user-notification-table.component";

@Component({
  selector: "app-user-list",
  templateUrl: "./portfolio.component.html",
  styleUrls: ["./portfolio.component.scss"],
})
export class PortfolioComponent implements OnInit {
  /* CHILD COMPONENTS */
  @ViewChild("tickerFilteredList", { static: false }) tickerFilteredList;
  @ViewChild("optionTypeFilteredList", { static: false })
  optionTypeFilteredList;
  @ViewChild("acctFilteredList", { static: false }) acctFilteredList;
  @ViewChild("expirationFilteredList", { static: false })
  expirationFilteredList;
  @ViewChild("categoryFilteredList", { static: false }) categoryFilteredList;
  @ViewChild("partialFilteredList", { static: false }) partialFilteredList;
  @ViewChild("tableFilteredList", { static: false }) tableFilteredList;
  @ViewChild("viewRowChild") viewChildRow: ElementRef;
  @ViewChild("contextMenu") contextMenu: any;
  // @ViewChild('user') child:ChildCmp;

  @ViewChild("userTable", { static: false })
  userTableChild: UserNotificationTableComponent;

  @HostListener("document:click", ["$event"])
  public documentClick(event: Event): void {
    // This setTimeout is to give the dropdown a chance to close before we check if it's open
    setTimeout(() => {
      let elementId: string = (event.target as Element).id;

      // Only run this if we dont click on a leg cell or input, this way we dont disable editing on the cell we clicked
      if (
        !elementId.startsWith("leg") &&
        !(event.target instanceof HTMLInputElement)
      ) {
        this.disableEditingOnAllCells();

        const dropdownsOpen =
          this.elem.nativeElement.querySelectorAll(".show.dropdown").length == 0
            ? false
            : true;

        // Only disable editing if we also have no new legs added and no dropdowns are open.
        // Otherwise set editing to true so we don't reload the data
        if (
          this.numNewRowsExisting == 0 &&
          this.numNewRows == 0 &&
          !dropdownsOpen
        ) {
          this.userMakingEdit = false;
        } else {
          this.userMakingEdit = true;
        }
      }
    }, 100);
  }
  @HostListener("document:contextmenu", ["$event"])
  public documentRClick(event: Event): void {
    this.menuState = false;
  }

  selectedStrat: any;

  notificationDate: any;
  currentUserDetails: any;
  allColumns = [];
  filtersCollapsed = false;
  filterCriteria = [];
  subGetEssentialData: Subscription;
  subGetLoggingData: Subscription;
  originalData: any = [];
  portfolio = [];
  strategies: any;
  symbols: any;
  loading: boolean;
  fullscreen: boolean;
  closedOpenStatus: any;
  user: any;
  userId: any;
  dateColumn: any;
  acctColumn: any;
  legColumn: any;
  tickerColumn: any;
  daysColumn: any;
  expirationColumn: any;
  quantityColumn: any;
  strikeColumn: any;
  optionTypeColumn: any;
  UDLColumn: any;
  ITMcurColumn: any;
  moveColumn: any;
  costColumn: any;
  priceColumn: any;
  percentGainColumn: any;
  maxGainColumn: any;
  percentMaxColumn: any;
  exitColumn: any;
  exitPriceColumn: any;
  notesColumn: any;
  strategyColumn: any;
  typeColumn: any;
  categoryColumn: any;
  tableNumColumn: any;
  qualityColumn: any;
  exposureCostColumn: any;
  exposureCurValColumn: any;
  $GainColumn: any;
  percentOfFVColumn: any;
  priceAtFillColumn: any;
  paidColumn: any;
  bidColumn: any;
  midColumn: any;
  askColumn: any;
  totalPaidColumn: any;
  valueColumn: any;
  totalGainColumn: any;
  minCashValueColumn: any;
  maxValueColumn: any;
  targetValueColumn: any;
  targetProfitColumn: any;
  paidExposureDashboardColumn: any;
  maxValueExposureDashboardColumn: any;
  ITM100Column: any;
  ITMNeg25Column: any;
  ITMNeg25to0Column: any;
  ITM0to25Column: any;
  ITM25Column: any;
  useTableColumn: any;
  portfolioIdColumn: any;
  noColumn: any;
  priceOpenColumn: any;
  priceCloseColumn: any;
  archiveList = new Array();
  tvDollarDiscount: any;
  tvSecondSellingPointInput: any;
  _column: any;
  column: any;
  bubbleList: any;
  tableName: any;
  allFilters: any;
  defEnd: any;
  selectedStratGroup: any = [];
  selectedExitGroup: any = [];
  editNote1: any;
  editNote2: any;
  sortObj: any;
  clear: any;
  numNewRows = 0;
  activeRightClickRecord: any;
  newPortForm: any = [];
  existingPortForm: any = [];
  closeResult: string;
  showRightClickConfirmation: any;
  showRightClickCloseConfirmation: any;
  rightClickLegIndex: any;
  userQuantityEntered: any;
  rightClickMenuState: any;
  jobMessage: any = "";
  realTimeDataSub: any;

  newPortfolioFormData: any = {
    trade_account_id: null,
    strategy_id: null,
    purchased_at: null,
    symbol: null,
    security_type_1: null,
    security_type_2: null,
    security_type_3: null,
    security_type_4: null,
    expiration: null,
    quantity: null,
    strike_1: null,
    strike_2: null,
    strike_3: null,
    strike_4: null,
    price_at_fill: null,
    paid_1: null,
    paid_2: null,
    use_table: null,
    notes_1: null,
    notes_2: null,
    notes_3: null,
    notes_4: null,
    exit: null,
  };

  requiredPortfolioData = [];
  openClosedLoading: boolean = false;
  addNewRecord: boolean;
  tradeAccts: any;
  spreadNoteDate: any;
  newlyAddedRecords: any;
  createPortfolioError: boolean;
  portfolioList = new Array();
  popoverNote1Active: boolean;
  mouseEvent: any;
  closedOpenList: any;
  filterGetDataEventSub: any;
  filterEventSub: any;
  filterBubbleClosedSub: any;
  filterSortSub: any;
  activeRightClickLeg: any;
  activeRightClickQuantity: any;

  rcModalLoading: boolean = false;
  rcModalDateError: boolean = false;
  portfolioRecordHistoryData: any;
  numNewRowsExisting = 0;
  currLegUserIsEditingIndex: any;

  rightClickPriceVal: any;
  rightClickQuantityVal: any;
  userMakingEdit = false;
  sortingAndFilteringInProgress: boolean = false;
  /* All Stuff For Right Click Menu */
  addNewRecordToExisting: boolean = false;
  usersLoading: any;
  legBeingRightClicked: any;
  windowWidth: any;
  rightClicked: any;
  rightClick: any;
  menuState: any;
  menuPositionY: any;
  menuPositionX: any;
  windowHeight: any;
  menuWidth: any;
  menuHeight: any;
  menuPosition: any;
  showHoverForNotes: any;
  noteText: any;
  spreadHistory: any = {};
  historyLoading: boolean = true;
  userMakingEditPortData: any = false;
  incomingFill: any;

  /* cell value array holds data of what was right clicked in an array */
  cellValueArr: any;

  noteEqualsValue: any;
  selectedComparisonForValueNotification: any = "Equals";
  selectedPercent: any = "Moves Up";
  percentTimespan: any = "Today Only";
  noteType: any = "Value Notification";
  percentNoteEqualsValue: any;

  percentMovesUp: any;
  percentMovesDown: any;
  spreadNoteMessage: any = "";
  spreadNoteUsers: any;
  spreadUsers: any;
  noteUntilDate: any;
  users: any;
  showPopulateMovesUpError: any;

  echo: any;
  notificationModal: any;

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private elem: ElementRef,
    private portfolioService: PortfolioService,
    private formulaService: FormulaService,
    private strategiesService: StrategiesService,
    private filterService: FilterService,
    public authService: AuthenticationService,
    public fullScreenService: FullScreenService,
    public sortService: SortService,
    private toastr: ToastrService,
    private envService: RuntimeEnvLoaderService,
    public utilsService: UtilsService,
    private modalService: NgbModal,
    private notificationsService: NotificationsService,
    private userService: UserService
  ) {
    this.sortObj = {
      sortBy: "",
      sortOrder: "",
    };

    this.currentUserDetails = localStorage.getItem("currentUser");
    let loggedUser = JSON.parse(localStorage.getItem("currentUser"));
    this.userId = loggedUser.id;
  }

  joinWebsocket() {
    this.echo = new Echo({
      broadcaster: "pusher",
      key: "dorian", // hard code
      wsHost: this.envService.config.WEBSOCKET_HOST,
      wssHost: this.envService.config.WEBSOCKET_HOST,
      enabledTransports: ["ws", "wss"],
      wssPort: this.envService.config.WEBSOCKET_PORT,
      wsPort: this.envService.config.WEBSOCKET_PORT,
      forceTLS: false,
      useTLS: false,
      disableStats: true,
    });

    this.echo
      .channel("channel-portfolio")
      .listen("EventCalculatedPortfolio", (data) => {
        console.log("WEBSOCKET RECEIVED");
        console.log(data);

        if (!this.userMakingEdit) {
          this.getData(false);
        }
      });
  }

  leaveWebsocket() {
    if (this.echo) {
      console.log("LEAVING CHANNEL ECHO");
      this.echo.leaveChannel("channel-portfolio");
    }
  }

  ngOnInit() {
    this.joinWebsocket();
    this.portfolioLegs();
    this.numNewRows = 0;
    this.newlyAddedRecords = [];
    this.addNewRecord = false;
    this.tableName = "portfolio";
    this.user = JSON.parse(this.currentUserDetails);
    this.initColumns();
    this.loading = true;
    this.getData(true);
    this.getStrategiesData();
    this.getTradeAccountData();
    this.getUsers();
    this.popoverNote1Active = false;
    this.closedOpenStatus = "Show Open";

    this.subscribeToSortChangedObservable();
    this.subscribeToFilterAppliedObservable();
    this.subscribeToBubbleClosedObservable();

    fromEvent(document.body, "mousemove").subscribe((e) => {
      this.mouseEvent = e;
    });

    let that = this;
    setInterval(function () {
      if (that.userMakingEdit) {
        that.userMakingEditPortData = true;
      }
    }, 60 * 1000);
  }

  getUsers() {
    this.usersLoading = true;
    this.userService.getUserList().subscribe((data) => {
      this.users = data.body.data;
      this.usersLoading = false;
    });
  }

  clickCheck($event) {
    this.spreadUsers = $event;
  }

  disableEditingOnAllCells() {
    this.portfolio.forEach((leg) => {
      let editKeys = Object.keys(leg).filter((key) => key.includes("edit"));
      editKeys.forEach((key) => {
        leg[key] = false;
      });
    });
  }

  setNotificationType(noteType) {
    this.noteType = noteType;
  }

  addNotification(content, notification) {
    this.openNotification(content, "TLR");
    this.notificationsService.setCurrNotification(notification);
  }

  addSpreadNotification(content, notification) {
    this.openNotification(content, "TLR");
    this.notificationsService.setCurrNotification(notification);
  }

  openNotification(content, symbol) {
    this.notificationModal = this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: "lg" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  formatSpreadDate(date) {
    return date.substring(0, 10);
  }

  public items = [
    { name: "John", otherProperty: "Foo" },
    { name: "Joe", otherProperty: "Bar" },
  ];

  viewTicker(content, symbol) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: "lg" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    var url =
      this.envService.config.API_BASE_URL +
      "/url-framer.php?url=https://finance.yahoo.com/quote/" +
      symbol +
      "/";

    document.getElementById("modal-basic-title").innerHTML =
      "Live Data: " + symbol;
    document.getElementById("ticker-live-data").setAttribute("src", url);
  }

  getPortfolioTopMessage() {
    this.userMakingEditPortData = false;

    this.getData(false);
  }

  exportToExcel() {
    let tenK = "";
    let params = new HttpParams().set("at_10k", "10000").set("format", "csv");

    this.filterEventSub = this.portfolioService
      .getPortfolio(params)
      .subscribe((result) => {
        let res = {
          contentType: result.body.data.contentType,
          base64FileData: result.body.data.base64FileData,
        };

        FileSaver.saveAs(
          new Blob([atob(res.base64FileData)], { type: res.contentType }),
          "portfolio.csv"
        );
      });
  }

  addNewLegToExisting(event) {
    /* Get the Index of the Portfolio Item that was clicked */
    event.stopPropagation();
    event.preventDefault();
    this.userMakingEdit = true;
    this.numNewRowsExisting = 1;
    this.addNewLegToExistingSpread();
  }

  closeRightClickWindow() {
    this.clearRightClickOnAllRows();
    this.rightClickMenuState = false;
  }

  showHoverNotes(event, noteText) {
    if (!noteText) {
      return;
    }

    this.noteText = noteText;
    this.showHoverForNotes = true;
    event.stopPropagation();
    this.positionMenu(event);
    event.preventDefault();
  }

  hideHoverNotes() {
    this.showHoverForNotes = false;
  }

  clearRightClickOnAllRows() {
    for (var i = 0; i < this.portfolio.length; i++) {
      this.portfolio[i].addNewRecordToExisting = false;
    }
  }

  // Optional
  onRightClick(event, data) {
    this.clearRightClickOnAllRows();
    data.addNewRecordToExisting = true;
    this.rightClickMenuState = true;
    this.userQuantityEntered = 0;
    this.rightClicked = data;
    event.stopPropagation();
    // this.menuState=true;
    this.positionMenu(event);
    this.parseRightClickCell(event);
    event.preventDefault();
  }

  parseRightClickCell(tdValue) {
    let idVal = tdValue.path[0].id;
    this.cellValueArr = idVal.split(":");
  }

  getPosition(e) {
    var posx = 0;
    var posy = 0;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }
    return { x: posx, y: posy };
  }

  positionMenu(e) {
    this.menuPosition = this.getPosition(e);
    this.menuPositionX = this.menuPosition.x;
    this.menuPositionY = this.menuPosition.y;
    this.cdRef.detectChanges();
    this.menuWidth = this.contextMenu.nativeElement.offsetWidth;
    this.menuHeight = this.contextMenu.nativeElement.offsetHeight;
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    if (this.windowWidth - this.menuPositionX < this.menuWidth) {
      this.menuPositionX = this.windowWidth - this.menuWidth + "px";
    } else {
      this.menuPositionX = this.menuPositionX + "px";
    }
    if (this.windowHeight - this.menuPositionY < this.menuHeight) {
      this.menuPositionY = this.windowHeight - this.menuHeight + "px";
    } else {
      this.menuPositionY = this.menuPositionY + "px";
    }
  }

  ngOnDestroy() {
    this.leaveWebsocket();
    this.bubbleList = [];

    if (this.filterEventSub) {
      this.filterEventSub.unsubscribe();
    }
    if (this.filterGetDataEventSub) {
      this.filterGetDataEventSub.unsubscribe();
    }
    if (this.filterSortSub) {
      this.filterSortSub.unsubscribe();
    }
    if (this.filterBubbleClosedSub) {
      this.filterBubbleClosedSub.unsubscribe();
    }
    if (this.realTimeDataSub) {
      this.realTimeDataSub.unsubscribe();
    }
  }

  portfolioLegs() {
    this.portfolioService.legDeleted.subscribe((data) => {
      if (!data) {
        return;
      }

      let leg = data.body.data.original.data;
      let rmvPortIndex;
      let rmvLegIndex;

      for (var i = 0; i < this.portfolio.length; i++) {
        if (this.portfolio[i].legs) {
          for (var l = 0; l < this.portfolio[i].legs.length; l++) {
            if (leg.id == this.portfolio[i].legs[l].portfolio_leg_id) {
              rmvLegIndex = l;
              rmvPortIndex = i;
            }
          }
        }
      }
      if (typeof rmvLegIndex === "number") {
        this.portfolio[rmvPortIndex].legs.splice(rmvLegIndex, 1);
      }
    });
  }

  confirmCloseLeg(indexOfLeg) {
    this.showRightClickCloseConfirmation = true;
    this.rightClickLegIndex = indexOfLeg;

    if (!this.rightClickPriceVal) {
      this.rightClickPriceVal =
        this.rightClicked.legs[this.rightClickLegIndex].price;
    }

    if (!this.rightClickQuantityVal) {
      this.rightClickQuantityVal =
        this.rightClicked.legs[this.rightClickLegIndex].quantity;
    }
  }

  confirmDeleteLeg(indexOfLeg) {
    this.showRightClickConfirmation = true;
    this.rightClickLegIndex = indexOfLeg;
  }

  rightClickQuantity(quantity, leg) {
    this.rightClickQuantityVal = quantity;
    this.legBeingRightClicked = leg;
  }

  rightClickPrice(price, leg) {
    this.rightClickPriceVal = price;
    this.legBeingRightClicked = leg;
  }

  deleteLeg() {
    this.showRightClickConfirmation = false;

    let params = new HttpParams().set(
      "legId",
      this.rightClicked.legs[this.rightClickLegIndex].portfolio_leg_id
    );

    this.loading = true;
    this.rcModalLoading = true;
    this.portfolioService.deletePortfolioItem(params).subscribe((result) => {
      this.rightClicked.deleted = true;
      this.rightClicked.closed = true;
      this.rcModalLoading = false;
      this.toastr.success("Record Closed Successfully");
      this.loading = false;

      if (this.rightClicked.legs.length < 1) {
        this.menuState = false;
      }
    });
  }

  closeLeg() {
    /* Error Checking if they do not specify the date. */
    if (this.rightClickPriceVal) {
      this.legBeingRightClicked.price = this.rightClickPriceVal;
    }

    if (this.rightClickQuantityVal) {
      this.legBeingRightClicked.quantity = this.rightClickQuantityVal;
    }

    /* End Error Checking */
    this.rcModalLoading = true;
    let params = new HttpParams()
      .set(
        "closeIds",
        this.rightClicked.legs[this.rightClickLegIndex].portfolio_leg_id
      )

      .set("price_at_close", this.rightClickPriceVal)

      .set("quantity", this.rightClickQuantityVal);

    this.portfolioService.closePortfolioItem(params).subscribe((result) => {
      this.showRightClickCloseConfirmation = false;
      this.rightClicked.legs[this.rightClickLegIndex].closed = true;
      this.rcModalLoading = false;
      this.toastr.success("Record Closed Successfully");
    });
  }

  setPopoverNote(record, text, val) {
    record.active = val;
  }

  removeAllFilters() {
    this.clear = 1;
    this.allFilters = [];
    this.editNote1 = false;
    this.editNote2 = false;
    this.bubbleList = [];
    this.sortService.clearLastSorted();
    this.sortObj.sortOrder = "desc";
    this.sortObj.sortBy = "purchased_at";
    this.sortAndFilter();
    this.filterService.bubbleClosed([]);
  }

  changeClosedOpenView(status) {
    this.loading = true;

    if (status == "show_all") {
      this.closedOpenStatus = "Show All";
    } else if (status == "show_closed") {
      this.closedOpenStatus = "Show Closed";
    } else if (status == "show_open") {
      this.closedOpenStatus = "Show Open";
    }

    this.sortAndFilter();
  }

  select(ab) {}

  selectSelect(record) {
    record.selected = !record.selected;
  }

  unarchivePortfolioItem(portfolioItem) {
    Swal.fire({
      title: "Are you sure?",
      text: "This will unarchive this record.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.value) {
      }
    });
  }

  archiveSelectedPortfolioItems() {
    // this.loading = true;
    let totalRow = this.archiveList.length / 2;
    Swal.fire({
      title: "Are you sure?",
      text: "This will archive the " + totalRow + " selected records.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.value) {
        const historicalOrderIds = [];
        this.portfolio.forEach((leg) => {
          if (leg.checkStatus == true) {
            const logUser = JSON.parse(localStorage.getItem("currentUser"));
            if (logUser.id != leg.id) {
              // historicalOrderIds.push(this.portfolioService.archivePortfolioItem(Number(historicalOrder.fill_id_1)));
              // historicalOrderIds.push(this.portfolioService.archivePortfolioItem(Number(historicalOrder.fill_id_2)));
            } else {
              totalRow -= 1;
            }
          }
        });

        this.subGetEssentialData = forkJoin(historicalOrderIds).subscribe(
          (response) => {
            if (response[0] == "200") {
              this.archiveList = [];
              this.toastr.success(
                totalRow + " rows",
                "Records Archived Successfully"
              );
              // this.getData(this.allFilters, 0, true, false, this.showArchived);
            }
          },
          (err) => {
            this.toastr.error(totalRow + " rows", "Archive Unsuccessful");
          }
        );
      }
    });
  }

  subscribeToFilterAppliedObservable() {
    this.filterGetDataEventSub =
      this.filterService.filterAppliedObservable.subscribe(
        (data: FilterResult) => {
          if (!data.allFilters) {
            this.allFilters = [];
            return;
          }

          this.bubbleList = data.bubbleList;
          this.allFilters = data.allFilters;
          this.sortAndFilter();
        }
      );
  }

  subscribeToBubbleClosedObservable() {
    this.filterBubbleClosedSub =
      this.filterService.bubbleClosedObservable.subscribe(
        (data: FilterResult) => {
          if (!data) {
            this.allFilters = [];
            this.bubbleList = [];
            return;
          }

          this.allFilters = data.allFilters;
          this.bubbleList = data.bubbleList;

          this.sortAndFilter();
        }
      );
  }

  subscribeToSortChangedObservable() {
    this.filterSortSub = this.sortService.sortChangedObservable.subscribe(
      (lastSorted) => {
        if (
          !lastSorted || // If this sort is the same as the one we have then dont do anything
          (this.sortObj.sortBy == lastSorted["sortBy"] &&
            this.sortObj.sortOrder == lastSorted["sortOrder"])
        ) {
          return;
        }

        this.sortObj.sortBy = lastSorted["sortBy"];
        this.sortObj.sortOrder = lastSorted["sortOrder"];

        this.sortAndFilter();
      }
    );
  }

  sortAndFilter(showLoading = true) {
    if (showLoading) {
      document.body.classList.add("wait");
    }

    if (!this.originalData || this.originalData.length === 0) {
      return;
    }

    setTimeout(() => {
      // If we are editing dont update the data
      // This is just in case getData was called and an edit was made before the data was loaded
      if (this.userMakingEdit) {
        return;
      }

      this.sortingAndFilteringInProgress = true;

      const fields = {
        po_filters: JSON.stringify(this.allFilters),
        po_sort_by: this.sortObj.sortBy,
        po_sort_order: this.sortObj.sortOrder,
      };

      this.utilsService.saveUserFields(fields).subscribe();

      this.bubbleList = this.filterService.createFilterBubbleList(
        this.allFilters
      );

      let closedOpenStatusData = this.originalData;

      // Do the closed/open status filtering here since this is a custom filter
      if (this.closedOpenStatus == "Show Open") {
        closedOpenStatusData = this.originalData.filter((portfolioItem) => {
          return portfolioItem.closed_at == null;
        });
      } else if (this.closedOpenStatus == "Show Closed") {
        closedOpenStatusData = this.originalData.filter((portfolioItem) => {
          return portfolioItem.closed_at != null;
        });
      }

      let allLegs = [];

      // Sort and filter all the legs, then get the parent portfolio items for each leg until we load them all
      closedOpenStatusData.forEach((portfolioItem) => {
        portfolioItem.legs.forEach((leg) => {
          allLegs.push(leg);
        });
      });

      allLegs = this.sortService.sort(allLegs, this.sortObj);

      allLegs = this.filterService.filter(allLegs, this.allFilters);

      const portfolioItems = [];

      allLegs.forEach((leg) => {
        const portfolioItem = this.originalData.find(
          (po) => po.portfolio_item_id == leg.portfolio_item_id
        );

        if (!portfolioItems.includes(portfolioItem)) {
          portfolioItems.push(portfolioItem);
        }
      });

      // Set the background colors for each portfolio leg that are part of the same parent portfolio item
      let rowNumber = 0;
      let portfolioLegs = [];

      portfolioItems.forEach((portfolioItem) => {
        portfolioItem.legs.forEach((leg) => {
          leg.backgroundColor = rowNumber % 2 === 0 ? "white" : "#dbf6ff";

          portfolioLegs.push(leg);
        });

        rowNumber++;
      });

      this.portfolio = portfolioLegs;

      document.body.classList.remove("wait");

      this.loading = false;
      this.sortingAndFilteringInProgress = false;
    }, 200);
  }

  initColumns() {
    function createCol(name, dbName, hidden, filters, sort, isFiltered) {
      return {
        columnName: name,
        dbName: dbName,
        hidden: hidden,
        filters: filters,
        sort: sort,
        isFiltered: isFiltered,
      };
    }

    function filterTypeOne() {
      return {
        contains: "",
        greaterThan: "",
        lessThan: "",
      };
    }

    function filterTypeTwo() {
      return {
        contains: "",
      };
    }

    this.dateColumn = createCol("Date", "purchased_at", false, {}, null, false);

    this.acctColumn = createCol(
      "Acct",
      "trade_account_name",
      false,
      filterTypeTwo(),
      null,
      false
    );

    this.legColumn = createCol(
      "Leg",
      "leg",
      false,
      filterTypeOne(),
      null,
      false
    );

    this.tickerColumn = createCol("Ticker", "symbol", false, [], null, false);

    this.daysColumn = createCol(
      "Days",
      "expiration_days_away",
      false,
      filterTypeOne(),
      null,
      false
    );

    //   // Expiration
    this.expirationColumn = createCol(
      "Expiration Date",
      "expiration_date",
      false,
      {},
      null,
      false
    );

    this.quantityColumn = createCol(
      "Qty",
      "quantity",
      false,
      filterTypeOne(),
      null,
      false
    );

    this.strikeColumn = createCol(
      "Strike",
      "strike",
      false,
      filterTypeOne(),
      null,
      false
    );
    //   // optionType
    this.optionTypeColumn = createCol(
      "Option Type",
      "security_type",
      false,
      [],
      null,
      false
    );
    this.UDLColumn = createCol(
      "UDL",
      "udl",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.ITMcurColumn = createCol(
      "ITM(cur)",
      "itm_curr",
      false,
      filterTypeOne(),
      null,
      false
    );

    this.moveColumn = createCol(
      "Move",
      "move",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.costColumn = createCol(
      "Cost",
      "cost",
      false,
      filterTypeOne(),
      null,
      false
    );

    this.priceColumn = createCol(
      "Price",
      "price",
      false,
      filterTypeOne(),
      null,
      false
    );

    this.percentGainColumn = createCol(
      "% Gain",
      "gain_percent",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.maxGainColumn = createCol(
      "Max Gain",
      "gain_max",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.percentMaxColumn = createCol(
      "% Max",
      "max_percent",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.exitColumn = createCol(
      "Exit",
      "exit",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.exitPriceColumn = createCol(
      "Exit Price",
      "exit_price",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.notesColumn = createCol(
      "Notes",
      "notes",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.strategyColumn = createCol(
      "Strategy",
      "strategy",
      false,
      filterTypeOne(),
      null,
      false
    );

    this.typeColumn = createCol("Type", "symbol_type", false, [], null, false);

    this.categoryColumn = createCol(
      "Category",
      "symbol_category",
      false,
      [],
      null,
      false
    );

    this.exposureCostColumn = createCol(
      "Exp(cost)",
      "exp_cost",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.exposureCurValColumn = createCol(
      "Exp(cur val)",
      "exp_curr",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.$GainColumn = createCol(
      "$ Gain",
      "gain_dollars",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.percentOfFVColumn = createCol(
      "% FV",
      "fv_percent",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.priceAtFillColumn = createCol(
      "Price @ Fill",
      "price_at_fill",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.paidColumn = createCol(
      "Paid",
      "paid",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.bidColumn = createCol(
      "Bid",
      "bid",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.midColumn = createCol(
      "Mid",
      "mid",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.askColumn = createCol(
      "Ask",
      "ask",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.totalPaidColumn = createCol(
      "Total Paid",
      "paid_total",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.valueColumn = createCol(
      "Value $",
      "value",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.totalGainColumn = createCol(
      "Total Gain $",
      "gain_total",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.minCashValueColumn = createCol(
      "Min Cash Value",
      "value_min",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.maxValueColumn = createCol(
      "Max Value",
      "value_max",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.targetValueColumn = createCol(
      "Target Value",
      "value_target",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.targetProfitColumn = createCol(
      "Target Profit",
      "profit_target",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.paidExposureDashboardColumn = createCol(
      "Paid for Exposure Dashboard",
      "todo",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.maxValueExposureDashboardColumn = createCol(
      "Max Value for Exposure Dashboard",
      "max_value_exposure_dashboard",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.ITM100Column = createCol(
      "ITM 100%+",
      "itm_1",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.ITMNeg25Column = createCol(
      "ITM -25%+",
      "itm_2",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.ITMNeg25to0Column = createCol(
      "ITM -25% - 0%",
      "itm_3",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.ITM0to25Column = createCol(
      "ITM 0% - 25%",
      "itm_4",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.ITM25Column = createCol(
      "ITM 25%+",
      "itm_5",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.portfolioIdColumn = createCol(
      "Id",
      "portfolio_item_id",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.useTableColumn = createCol(
      "Use Table?",
      "use_table",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.priceOpenColumn = createCol(
      "Price Open",
      "todo",
      false,
      filterTypeOne(),
      null,
      false
    );
    this.priceCloseColumn = createCol(
      "Price Close",
      "todo",
      false,
      filterTypeOne(),
      null,
      false
    );

    this.allColumns.push(this.dateColumn);
    this.allColumns.push(this.acctColumn);
    this.allColumns.push(this.legColumn);
    this.allColumns.push(this.tickerColumn);
    this.allColumns.push(this.daysColumn);
    this.allColumns.push(this.expirationColumn);
    this.allColumns.push(this.quantityColumn);
    this.allColumns.push(this.strikeColumn);
    this.allColumns.push(this.optionTypeColumn);
    this.allColumns.push(this.UDLColumn);
    this.allColumns.push(this.ITMcurColumn);
    this.allColumns.push(this.moveColumn);
    this.allColumns.push(this.costColumn);
    this.allColumns.push(this.priceColumn);
    this.allColumns.push(this.percentGainColumn);
    this.allColumns.push(this.maxGainColumn);
    this.allColumns.push(this.percentMaxColumn);
    this.allColumns.push(this.exitColumn);
    this.allColumns.push(this.exitPriceColumn);
    this.allColumns.push(this.notesColumn);
    this.allColumns.push(this.strategyColumn);
    this.allColumns.push(this.typeColumn);
    this.allColumns.push(this.categoryColumn);
    this.allColumns.push(this.exposureCostColumn);
    this.allColumns.push(this.exposureCurValColumn);
    this.allColumns.push(this.$GainColumn);
    this.allColumns.push(this.percentOfFVColumn);
    this.allColumns.push(this.priceAtFillColumn);
    this.allColumns.push(this.paidColumn);
    this.allColumns.push(this.bidColumn);
    this.allColumns.push(this.midColumn);
    this.allColumns.push(this.askColumn);
    this.allColumns.push(this.totalPaidColumn);
    this.allColumns.push(this.valueColumn);
    this.allColumns.push(this.totalGainColumn);
    this.allColumns.push(this.minCashValueColumn);
    this.allColumns.push(this.maxValueColumn);
    this.allColumns.push(this.targetValueColumn);
    this.allColumns.push(this.targetProfitColumn);
    this.allColumns.push(this.paidExposureDashboardColumn);
    this.allColumns.push(this.maxValueExposureDashboardColumn);
    this.allColumns.push(this.ITM100Column);
    this.allColumns.push(this.ITMNeg25Column);
    this.allColumns.push(this.ITMNeg25to0Column);
    this.allColumns.push(this.ITM0to25Column);
    this.allColumns.push(this.ITM25Column);
    this.allColumns.push(this.useTableColumn);
    this.allColumns.push(this.priceOpenColumn);
    this.allColumns.push(this.priceCloseColumn);
    this.allColumns.push(this.portfolioIdColumn);
  }

  setPercentSelected(val) {
    this.selectedPercent = val;
  }

  showAllColumns() {
    for (const col of this.allColumns) {
      col.hidden = false;
    }
  }

  showSpecificColumns(dbName) {
    for (const col of this.allColumns) {
      if (col.dbName == dbName) {
        if (col.hidden == true) {
          col.hidden = false;
        } else {
          col.hidden = true;
        }
      }
    }
  }

  getSelectedPortfolioItemList() {
    let selectedList = [];
    for (var i = 0; i < this.portfolio.length; i++) {
      if (this.portfolio[i].selected) {
        selectedList.push(this.portfolio[i].portfolio_item_id);
      }
    }

    return selectedList;
  }

  changeOpenClosed(id, changeTo) {
    for (var i = 0; i < this.portfolio.length; i++) {
      if (this.portfolio[i].portfolio_item_id == id) {
        this.portfolio[i].closed_at = changeTo;
      }
    }
  }

  openPortfolioItem(record) {
    if (record.openClosedLoading) {
      return;
    }

    record.openClosedLoading = true;
    let params = new HttpParams().set(
      "closeIds",
      JSON.stringify(record.portfolio_item_id)
    );

    this.subGetEssentialData = this.portfolioService
      .openPortfolioItem(params)
      .subscribe((res) => {
        record.openClosedLoading = false;
        let portfolioItem = res.body.data.original.data;
        this.changeOpenClosed(portfolioItem.id, portfolioItem.closed_at);
      });
  }

  closePortfolioItem(record) {
    if (record.openClosedLoading) {
      return;
    }

    record.openClosedLoading = true;
    let params = new HttpParams().set(
      "closeIds",
      JSON.stringify(record.portfolio_item_id)
    );

    this.subGetEssentialData = this.portfolioService
      .closePortfolioItem(params)
      .subscribe((res) => {
        record.openClosedLoading = false;
        let portfolioItem = res.body.data.original.data;
        this.changeOpenClosed(portfolioItem.id, portfolioItem.closed_at);
      });
  }

  getData(showLoading = true) {
    if (this.sortingAndFilteringInProgress) {
      return;
    }

    if (showLoading) {
      this.loading = true;
    }

    this.subGetEssentialData = this.portfolioService
      .getPortfolio()
      .subscribe((result) => {
        if (!result.body.data.original) {
          return;
        }

        // Dont reload if sorting or filtering is happening
        if (this.sortingAndFilteringInProgress) {
          return;
        }

        this.originalData = result.body.data.original.data;

        this.originalData.forEach(() => {
          let portfolioRecord = [];
          this.selectedStratGroup.push(portfolioRecord);
        });

        this.originalData.forEach((portfolio) => {
          if (portfolio && portfolio.legs) {
            portfolio.legs[0].firstLegDisplayed = true;
            portfolio.numOfLegs = portfolio.legs.length;
          }
        });

        // If we are reloading the data from the websocket dont clear the sort and filters
        if (showLoading) {
          this.allFilters = JSON.parse(result.body.data.original.filters);

          this.sortObj = {
            sortBy: result.body.data.original.sort_by,
            sortOrder: result.body.data.original.sort_order,
          };
        }

        this.filterService.setupFiltersForTable(
          this.portfolio,
          this.allFilters
        );

        this.sortAndFilter(showLoading);
      });
  }

  getStrategiesData() {
    this.subGetEssentialData = this.strategiesService
      .getStrategies()
      .subscribe((result) => {
        this.strategies = result.body.data;
      });
  }

  getTradeAccountData() {
    let table = {
      db_table: "trade_accounts",
      col: "name",
    };
    this.subGetEssentialData = this.utilsService
      .getTableData(table)
      .subscribe((result) => {
        this.tradeAccts = result.body.data.original.data;
      });
  }

  viewPortfolioRecordHistory(recordId, content) {
    this.historyLoading = true;

    this.subGetEssentialData = this.portfolioService
      .getPortfolioRecordHistory(recordId)
      .subscribe((result) => {
        this.portfolioRecordHistoryData = result.body.data;
        this.portfolio.forEach((leg) => {
          if (recordId == leg.portfolio_item_id) {
            this.spreadHistory["symbol"] = leg.symbol;
            this.spreadHistory["trade_account_name"] = leg.trade_account_name;
          }
        });
        this.historyLoading = false;
      });

    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: "lg" })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  addNewCustomPortfolioTableRow() {
    this.addNewRecord = true;
    this.numNewRows = 1;
    this.createNewLegRow(1, this.numNewRows);
    this.userMakingEdit = true;
  }

  newRowData(event, record_col) {
    let db_col = record_col;
    let data = event;
    this.newPortfolioFormData[record_col] = data;
  }

  changeInputExisting($event, row) {
    let inputName = $event.target.name;
    let inputVal = $event.target.value;

    if (inputVal == "") {
      this.existingPortForm[this.numNewRowsExisting - 1][inputName] = null;
    } else {
      this.existingPortForm[this.numNewRowsExisting - 1][inputName] = inputVal;
    }
  }

  changeInput($event, row) {
    let inputName = $event.target.name;
    let inputVal = $event.target.value;

    if (inputVal == null) {
      return;
    }

    if (inputVal == "") {
      this.newPortForm[row][inputName] = null;
    } else {
      this.newPortForm[row][inputName] = inputVal;
    }
  }

  addLegExisting(row) {
    this.numNewRowsExisting += 1;
    this.addNewLegToExistingSpread();
  }

  createNotification() {}

  removeLegExisting() {
    this.newPortForm.pop();
    this.numNewRowsExisting -= 1;
  }

  addLeg(row) {
    this.numNewRows += 1;
    this.createNewLegRow(row + 1, this.numNewRows);
  }

  removeLeg() {
    this.newPortForm.pop();
    this.numNewRows -= 1;
  }

  createExistingLegForm() {
    let portObj = {
      strategy_id: null,
      purchased_at: null,
      security_type: null,
      expiration: null,
      quantity: null,
      strike: null,
      price_at_fill: null,
      paid: null,
      notes: null,
      exit: null,
    };

    return portObj;
  }

  addNewLegToExistingSpread() {
    this.existingPortForm.push(this.createExistingLegForm());
  }

  createNewLegRow(i, numRows) {
    if (numRows == 1) {
      this.newPortForm[i] = {
        trade_account_id: null,
        strategy_id: null,
        purchased_at: null,
        symbol: null,
        security_type: null,
        expiration: null,
        quantity: null,
        strike: null,
        price_at_fill: null,
        paid: null,
        notes: null,
        exit: null,
      };
    } else {
      this.newPortForm[i] = {
        strategy_id: null,
        purchased_at: null,
        security_type: null,
        expiration: null,
        quantity: null,
        strike: null,
        price_at_fill: null,
        paid: null,
        notes: null,
        exit: null,
      };
    }
  }

  userInputErrorOnLegs(sumbitData) {
    this.createPortfolioError = false;

    for (var i = 1; i < sumbitData.length; i++) {
      for (let [key, value] of Object.entries(sumbitData[i])) {
        if (!this.requiredPortfolioData[i]) {
          this.requiredPortfolioData[i] = {};
        }

        //Required Data
        if (key == "security_type") {
          if (value == "Call" || value == "Put") {
            if (
              sumbitData[i].expiration == null ||
              sumbitData[i].strike == null
            ) {
              this.createPortfolioError = true;
              this.requiredPortfolioData[i].expiration = true;
              this.toastr.error("Expiration required!");
              this.requiredPortfolioData[i].strike = true;
              this.toastr.error("Strike required!");
            } else {
              this.requiredPortfolioData[i].expiration = false;
              this.requiredPortfolioData[i].strike = false;
            }
          } else {
            this.requiredPortfolioData[i].expiration = false;
            this.requiredPortfolioData[i].strike = false;
          }
        }

        if (value == null || value == "null") {
          if (
            key == "purchased_at" ||
            key == "symbol" ||
            key == "trade_account_id" ||
            key == "quantity" ||
            key == "security_type"
          ) {
            this.createPortfolioError = true;
            this.requiredPortfolioData[i][key] = true;
            if (this.requiredPortfolioData[i][key] == true) {
              this.toastr.error(key + " required!");
            }
          }
        } else {
          this.requiredPortfolioData[i][key] = false;
        }

        //Correct Data in Cells
        if (value != null) {
          if (key == "purchased_at" || key == "expiration") {
            //Date Validation
            let dateFormat = true;

            if (dateFormat) {
            }
          }
          if (
            key == "quantity" ||
            key == "strike" ||
            key == "quantity" ||
            key == "price_at_fill" ||
            key == "paid"
          ) {
            //Number validation
            let isNum = Number(value);
            if (!isNum) {
              this.requiredPortfolioData[i][key] = true;
              this.toastr.error(key + " must be a number!");
              this.createPortfolioError = true;
              isNum = null;
            } else {
              this.requiredPortfolioData[i][key] = false;
            }
            if (key == "quantity") {
              let num: any = value;
              let isInt: any = num.search(/\./);
              if (isInt > -1 && isInt != NaN) {
                this.requiredPortfolioData[i][key] = true;
                this.toastr.error(key + " cannot contain decimals!");
                this.createPortfolioError = true;
                isInt = null;
              } else {
                this.requiredPortfolioData[i][key] = false;
              }
            }
          }
          if (key == "exit") {
            //Valitate Exit
            let isNum = Number(value);
            if (value == "DEFAULT" || value == "MANUAL" || isNum) {
              isNum = null;
              this.requiredPortfolioData[i][key] = false;
            } else {
              this.requiredPortfolioData[i][key] = true;
              this.toastr.error(
                'Exit must be "Default", "Manual", or a user input number'
              );
              this.createPortfolioError = true;
              isNum = null;
            }
          }
        }
      }
    }
    return this.createPortfolioError;
  }

  submitNewLegs() {
    if (this.userInputErrorOnLegs(this.existingPortForm)) {
      this.toastr.error("Save Unsuccsessful");
      return;
    }

    let submitData = {};

    submitData["legs"] = [];
    // submitData['symbol'] = this.newPortForm[1].symbol;
    // submitData['trade_account_id'] = this.newPortForm[1].trade_account_id;
    submitData["user_id"] = this.userId;

    for (let i = 0; i < this.existingPortForm.length; i++) {
      let temp = {};
      temp["portfolio_item_id"] = this.rightClicked.portfolio_item_id;
      temp["leg_num"] = i;
      temp["purchased_at"] = this.existingPortForm[i].purchased_at;
      temp["strategy_id"] = this.existingPortForm[i].strategy_id;
      temp["security_type"] = this.existingPortForm[i].security_type;
      temp["expiration"] = this.existingPortForm[i].expiration;
      temp["quantity"] = this.existingPortForm[i].quantity;
      temp["strike"] = this.existingPortForm[i].strike;
      temp["price_at_fill"] = this.existingPortForm[i].price_at_fill;
      temp["paid"] = this.existingPortForm[i].paid;
      temp["notes"] = this.existingPortForm[i].notes;
      temp["exit"] = this.existingPortForm[i].exit;
      submitData["legs"].push(temp);
    }

    this.subGetEssentialData = this.portfolioService
      .createLegOnExistingSpread(submitData)
      .subscribe((result: any) => {
        const params = new HttpParams().set(
          "id",
          this.rightClicked.portfolio_item_id.toString()
        );

        this.portfolioService.getPortfolio(params).subscribe((result: any) => {
          this.rightClicked.legs = result.body.data.original.data[0].legs;
        });

        this.toastr.success("Created New Record, Save Succsessful");
        /* Clear existing Port Form */
        this.numNewRowsExisting = 0;
        this.existingPortForm = [];
        this.userMakingEdit = false;
        // this.rightClicked.legs.push();
      });
  }

  sumbitNewPortfolioRecord() {
    if (this.userInputErrorOnLegs(this.newPortForm)) {
      this.toastr.error("Save Unsuccsessful");
      return;
    }

    let submitData = {};
    submitData["legs"] = [];
    submitData["symbol"] = this.newPortForm[1].symbol;
    submitData["trade_account_id"] = this.newPortForm[1].trade_account_id;
    submitData["user_id"] = this.userId;

    for (let i = 1; i < this.newPortForm.length; i++) {
      let temp = {};
      temp["leg_num"] = i;
      temp["purchased_at"] = this.newPortForm[i].purchased_at;
      temp["strategy_id"] = this.newPortForm[i].strategy_id;
      temp["security_type"] = this.newPortForm[i].security_type;
      temp["expiration"] = this.newPortForm[i].expiration;
      temp["quantity"] = this.newPortForm[i].quantity;
      temp["strike"] = this.newPortForm[i].strike;
      temp["price_at_fill"] = this.newPortForm[i].price_at_fill;
      temp["paid"] = this.newPortForm[i].paid;
      temp["notes"] = this.newPortForm[i].notes;
      temp["exit"] = this.newPortForm[i].exit;
      submitData["legs"].push(temp);
    }

    // Submit Legs to API.
    this.subGetEssentialData = this.portfolioService
      .createPortfolioRecord(submitData)
      .subscribe((result: any) => {
        this.toastr.success("Created New Record, Save Succsessful");

        const params = new HttpParams().set("id", result.data.id);

        this.portfolioService.getPortfolio(params).subscribe((result: any) => {
          this.portfolio.unshift(...result.body.data.original.data);
        });
      });

    this.cancelAddNewPortfolioRecord();
  }

  cancelAddNewLegs() {
    this.existingPortForm = [];
    this.numNewRowsExisting = 0;
    this.userMakingEdit = false;
  }

  cancelAddNewPortfolioRecord() {
    this.newPortForm = [];
    this.createPortfolioError = false;
    this.addNewRecord = false;
    this.numNewRows = 0;
    this.userMakingEdit = false;
  }

  saveExit(value, id) {
    let temp = value;
    temp = temp.replace("%", "");
    let isNum = Number(temp);

    if (temp == "" || temp == null) {
      temp == "Default";
    }
    // return;
    this.portfolio.forEach((leg) => {
      if (id == leg.portfolio_leg_id) {
        leg.exit = temp;

        if (temp == "DEFAULT" || temp == "MANUAL" || isNum) {
          if (isNum) {
            temp = temp / 100;
          }
          const submitData = {
            exit: temp,
            user_id: this.userId,
          };
          leg.exit_err = false;
          this.subGetEssentialData = this.portfolioService
            .editPortfolioRecord(id, submitData)
            .subscribe(
              (result: any) => {
                this.toastr.success("Exit Saved Successfully");
                this.loading = false;
              },
              (err) => {
                this.toastr.error("Save Unsuccessful");
              }
            );
        } else {
          leg.exit_err = true;
          this.toastr.error(
            'Exit must be "Default", "Manual", or a user input number'
          );
        }
      }
    });
  }

  editCell(event, cell, record) {
    event.stopPropagation();
    event.preventDefault();

    this.showHoverForNotes = false;

    this.disableEditingOnAllCells();

    record["edit" + cell] = true;

    this.userMakingEdit = true;
  }

  saveNumericalCell(dbCol, value, id) {
    let displayName = dbCol.charAt(0).toUpperCase() + dbCol.slice(1);
    let temp = value;
    temp = temp.replace("$", "");
    temp = temp.replace("%", "");
    let isNum = Number(temp);

    const submitData = {
      [dbCol]: temp,
      user_id: this.userId,
    };

    this.portfolio.forEach((leg) => {
      if (id == leg.portfolio_leg_id) {
        leg[dbCol] = temp;
        let isInt: any = null;
        if (dbCol == "quantity") {
          isInt = temp.search(/\./);
          if (isInt > -1 && isInt != NaN) {
            this.toastr.error(displayName + " cannot contain decimals!");
          }
        }
        if ((isNum && isInt == -1) || (isNum && isInt == null)) {
          leg[dbCol + "_err"] = false;
          leg["edit" + dbCol] = false;
          this.subGetEssentialData = this.portfolioService
            .editPortfolioRecord(id, submitData)
            .subscribe(
              (result: any) => {
                if (dbCol == "price_at_fill") {
                  this.toastr.success("Price At Fill Saved Successfully");
                } else {
                  this.toastr.success(displayName + " Saved Successfully");
                }
                this.loading = false;
              },
              (err) => {
                this.toastr.error("Save Unsuccessful");
              }
            );
        } else {
          leg[dbCol + "_err"] = true;
          if (dbCol == "price_at_fill") {
            this.toastr.error("Price At Fill must be a number");
          } else {
            if (isInt == -1) {
              this.toastr.error(displayName + " must be a number");
            }
          }
        }
      }
    });
  }

  addInitialToNotes(record) {
    record.notes +=
      " \n -" + this.user.first_name[0] + " " + this.user.last_name[0];
  }

  saveTextCell(dbCol, value, id) {
    let displayName = dbCol.charAt(0).toUpperCase() + dbCol.slice(1);
    let temp = value;

    if (dbCol == "symbol") {
      temp = temp.toUpperCase();
    }

    const submitData = {
      [dbCol]: temp,
      user_id: this.userId,
    };

    if (dbCol == "notes") {
      submitData["notes"] +=
        " \n -" + this.user.first_name[0] + " " + this.user.last_name[0];
      let today = new Date().toISOString().slice(0, 10);
      submitData["notes"] += " (" + today + ")";

      temp += " \n -" + this.user.first_name[0] + " " + this.user.last_name[0];
      temp += " (" + today + ")";
    }

    this.portfolio.forEach((leg) => {
      if (dbCol == "notes") {
        if (id == leg.portfolio_leg_id) {
          leg[dbCol] = temp;
          leg["edit" + dbCol] = false;
        }
      } else {
        if (id == leg.portfolio_item_id) {
          leg[dbCol] = temp;
          leg["edit" + dbCol] = false;
        }
      }
    });

    this.subGetEssentialData = this.portfolioService
      .editPortfolioRecord(id, submitData)
      .subscribe(
        (result: any) => {
          this.toastr.success(displayName + " Saved Successfully");
          this.loading = false;

          this.userMakingEdit = false;
        },
        (err) => {
          this.toastr.error("Save Unsuccessful");
        }
      );
  }

  saveDateCell(dbCol, value, id) {
    let displayName = dbCol.charAt(0).toUpperCase() + dbCol.slice(1);
    let temp = value;

    //VALIDATE DATES

    const submitData = {
      [dbCol]: temp,
      user_id: this.userId,
    };

    this.portfolio.forEach((leg) => {
      if (id == leg.portfolio_leg_id) {
        if (dbCol == "expiration") {
          leg.expiration_date = temp;
        } else {
          leg[dbCol] = temp;
        }
        leg["edit" + dbCol] = false;
      }
    });

    this.subGetEssentialData = this.portfolioService
      .editPortfolioRecord(id, submitData)
      .subscribe(
        (result: any) => {
          if (dbCol == "purchased_at") {
            this.toastr.success("Purchased At Saved Successfully");
          } else {
            this.toastr.success(displayName + " Saved Successfully");
          }
          this.loading = false;
        },
        (err) => {
          this.toastr.error("Save Unsuccessful");
        }
      );
  }

  saveDropdownCell(dbCol, value, id) {
    if (value == null || value == "null") {
      return;
    }

    let displayName = dbCol.charAt(0).toUpperCase() + dbCol.slice(1);
    let temp = value;

    const submitData = {
      [dbCol]: temp,
      user_id: this.userId,
    };

    this.portfolio.forEach((leg) => {
      if (dbCol == "security_type") {
        if (id == leg.portfolio_leg_id) {
          leg[dbCol] = temp;
        }
      } else {
        if (id == leg.portfolio_item_id) {
          leg[dbCol] = temp;
        }
      }
    });

    this.subGetEssentialData = this.portfolioService
      .editPortfolioRecord(id, submitData)
      .subscribe(
        (result: any) => {
          if (dbCol == "trade_account_id") {
            this.toastr.success("Trade Account Saved Successfully");
          } else if (dbCol == "security_type") {
            this.toastr.success("Security Type Saved Successfully");
          } else {
            this.toastr.success(displayName + " Saved Successfully");
          }
          this.loading = false;
        },
        (err) => {
          this.toastr.error("Save Unsuccessful");
        }
      );
  }

  openYahooData(content, symbol) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: "lg" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    var url =
      this.envService.config.API_BASE_URL +
      "/url-framer.php?url=https://finance.yahoo.com/quote/" +
      symbol +
      "/";

    document.getElementById("modal-basic-title").innerHTML =
      "Live Data: " + symbol;
    document.getElementById("ticker-live-data").setAttribute("src", url);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  saveStrat(id, j) {
    const submitData = {
      strategy_id: this.selectedStratGroup[j].id,
      user_id: this.userId,
    };

    this.subGetEssentialData = this.portfolioService
      .editPortfolioRecord(id, submitData)
      .subscribe(
        (result: any) => {
          this.toastr.success("Strategy Saved Successfully");
          this.loading = false;
        },
        (err) => {
          this.toastr.error("Save Unsuccessful");
        }
      );
  }

  fullScreen() {
    this.fullScreenService.isFullScreen = !this.fullScreenService.isFullScreen;
    if (this.fullScreenService.isFullScreen) {
      this.filtersCollapsed = false;
    }
  }

  toggleFiltersCollapse() {
    if (this.filtersCollapsed == true) {
      this.filtersCollapsed = false;
    } else if (this.filtersCollapsed == false) {
      this.filtersCollapsed = true;
    }
  }

  setTVDollarDiscount(num) {
    const submitData = {
      formula: num,
    };
    //find correct id for tv$discount
    let params = { db_record: "tv_dollar_discount" };
    this.formulaService.findFormula(params).subscribe((result: any) => {
      let data = result.body.data;
      let id = null;
      if (data.length == 1) {
        id = data[0].id;
      }
      this.formulaService
        .updateFormulaDetails(id, submitData)
        .subscribe((result: any) => {
          this.userId = result.id;
          this.toastr.success("TV Dollar Discount successfully updated");
          // this.getFormulaData();
        });
    });
  }

  logout() {
    const isSimulating: boolean =
      localStorage.getItem("isSimulating") === "true";

    if (isSimulating) {
      this.authService.logoutFromSimulation();
      window.location.reload();
    } else {
      this.authService.logout();
      this.router.navigate(["/login"]);
    }
    this.fullScreenService.isFullScreen = false;
  }

  // This is a function that returns 0 to the sort of the bubble list. For some reason ngFor defaults to sort
  // alphabetically. *ngFor="let filter of bubbleList | keyvalue: returnZero". Adding returnZero will leave the
  // array in the correct order.
  returnZero(): number {
    return 0;
  }
}
